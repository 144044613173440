import React from 'react';
import { FaDiscord, FaTwitter, FaInstagram } from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="container-fluid text-center text-md-left">
        <div className="row">
          <div className="col-md-6 mt-md-0 mt-3"></div>

          <hr className="clearfix w-100 d-md-none pb-0" />

          <div className="col-md-0 mt-md-0 mt-3">
            <h5 className="text">Chubby Hamster Club ⓥ</h5>
            <ul className="list-unstyled">
              <li><a href="https://twitter.com/ChubbyHamsterClub"><FaTwitter className="footerIconSocial" /> Twitter</a></li>
              <li><a href="https://discord.com/invite/VSn6X9eNEm"><FaDiscord className="footerIconSocial" /> Discord</a></li>
              {/* <li><a href="https://www.instagram.com/BlackVeNews/"><FaInstagram className="footerIconSocial" /> Instagram</a></li> */}
            </ul>
            <h5 className="text">AfterMarket</h5>
            <ul className="list-unstyled">
              <li><a href="https://blackvemarket.com/">BlackVeMarket</a></li>
            </ul>
          </div>

         
        </div>
      </div>

      <div className="footer-copyrigh text-center py-3">
        © 2023 BlackVeMarket. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
