
import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Mint from './pages/Mint';
import Header from './components/Header';
import Footer from './components/Footer';
import Assets from './pages/Assets';
import Staking from './pages/Staking';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
//import "~bootstrap/scss/bootstrap";
import './App 2.css';
/* The following line can be included in your src/index.js or App.js file */


function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
        
                <Route element={< Mint />} path='/'/>
                <Route element={< Assets />} path='/pages/Assets'/>
                <Route element={< Staking />} path='/pages/Staking'/>
                <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Coming Soon</p>
                  </main>
      }
    />
            </Routes>
            <ToastContainer />
            <Footer/>
        </BrowserRouter>
    );
}

export default App;

   // <Route element={< Game />} path='/pages/Game'/>
