import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/images/Logo.png';
import {connex, WalletAddress, User, CoinContract} from "../constants/Blockchain";
import {useEffect, useState} from "react";
import { RiLoginBoxLine} from "react-icons/ri";
import { FaWallet, FaDiscord, FaTwitter, FaChevronUp ,FaAlignJustify} from "react-icons/fa";
import Button from 'react-bootstrap/Button';
import './Header.css';

function Header() {
  const connect = async () => {
    connex
        .vendor
        .sign('cert', {
            purpose: 'identification',
            payload: {
                type: 'text',
                content: 'Welcome User! Connect your wallet. ' 
            }
        })
        .request()
        .then(result => {
            localStorage.setItem(WalletAddress, result.annex.signer);
            document.location.href = "/";
        })
};

const disconnect = () => {
  localStorage.removeItem(WalletAddress);
  document.location.href = "/";

};


return (
  <Navbar collapseOnSelect expand="lg" bg="light" variant="light" sticky="top">
    <Container>

    {User === null ? (
        <Button variant="outline-light" size="sm" onClick={connect} className="button-85">
          <RiLoginBoxLine /> Connect wallet...
        </Button>
      ) : (
        <Button variant="outline-light" size="sm" onClick={disconnect} className="button-85">
          Disconnect...{User.substring(User.length - 7)}
        </Button>
      )}

      <Navbar.Brand>
        <img src={Logo} className="logo" alt="Logo" />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" className="menu-toggle" />
      <Navbar.Collapse id="responsive-navbar-nav" className="menu-collapse">
        <Nav className="me-auto">
          <Nav.Link href="/">Mint</Nav.Link>
          <Nav.Link href="/pages/Assets">Assets</Nav.Link>
          <NavDropdown title="AfterMarket" id="collasible-nav-dropdown" menuVariant="dark" drop="end">
            <NavDropdown.Item href="https://blackvemarket.com">BlackVeMarket</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>

      
    </Container>
  </Navbar>
);
}

export default Header;



