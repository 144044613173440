import { useState,useEffect,useRef } from "react";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import Table from 'react-bootstrap/Table';
import {connex, User, BVMSC,sleep,MaxSupply} from "../constants/Blockchain";
import {toast} from "react-toastify";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Loader} from "../components/Functions/Loader";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {abi} from "../constants/BVMSC";
import ex1 from '../assets/images/1.png';
import ex2 from '../assets/images/2.png';
import ex3 from '../assets/images/3.png';
import Carousel from 'react-bootstrap/Carousel';
import BigNumber from "bignumber.js";
import Logo from '../assets/images/Logo.png';

export const StyledImg3 = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 1px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 10%;
  border: "4px dashed red";
  @media (min-width: 340px) {
    width: 300px;
  
  }
  @media (min-width: 900px) {
    width:405px;
  
  }
  transition: width 0.5s;
`;

import('../pages/BVM.css');

const Mint = () => {
  	const [balanceOf, setBalanceOf] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mintAmount, setMintAmount] = useState(1);
    const [balanceNFTS, setBalanceNFTS] = useState("Undefined");
    const [UserNFTS, setUserNFTS] = useState(0);
    const [PriceVET, setPriceVET] = useState(0);
    const [walletAddress, setWallet] = useState(null); //laaste aangepast !!! '' stond er/
    const [UserIsWhitelisted, setisWhitelisted] = useState(false);
    const [IsUserWhitelisted, setUserisWhitelisted] = useState(false);
    const [claimWhitelisted, setclaimWhitelisted] = useState(false);
    const [walletOfOwner, setWalletofOwnerNFTS] = useState(0);
    const [PAUSED, setpaused] = useState(false);
    const findABI = (SCABI, method) => SCABI[SCABI.findIndex(mthd => mthd.name === method)]
    const State = {
      account: '',
        }
        
        useEffect(() => {
          setLoading(true);
          State.account = localStorage.getItem('wallet');
          setWallet(State.account);
          console.log("refresh",State.account)
       console.log("refresh",User)
       if (User){
         //  console.log("hier1")
         loadinfo();


       } }, []);

       useEffect(() => {
        let interval = setInterval(() => {
          if (User){
        
            loadinfo();
         
           }
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    const loadinfo =  async () => {
      setLoading(true);
      if(User !== null){
      const totalSupplyABI = findABI(abi, 'totalSupply');
      const method1 = connex.thor.account(BVMSC).method(totalSupplyABI);
      const result1 = await method1.call();
      const getBalanceNFTs = +result1.decoded[0];
      setBalanceNFTS(MaxSupply-getBalanceNFTs);
  
      console.log (getBalanceNFTs);
    
      const balanceOfABI = findABI(abi, 'balanceOf');
      const method2 = connex.thor.account(BVMSC).method(balanceOfABI);
      const result2 = await method2.call(User);
      const getUserNFTs = result2.decoded[0];
      console.log (getUserNFTs);
      setUserNFTS(getUserNFTs);
    
      const walletOfOwnerABI = findABI(abi, 'walletOfOwner');
      const method3 = connex.thor.account(BVMSC).method(walletOfOwnerABI);
      const result3 = await method3.call(User);
      const walletofOwnerNFTs = result3.decoded[0];
      console.log (walletofOwnerNFTs);
      setWalletofOwnerNFTS(walletofOwnerNFTs);
  
      const isWhitelistedABI = findABI(abi, 'isWhitelisted');
      const isWhitelistedmethod = connex.thor.account(BVMSC).method(isWhitelistedABI);
      const isWhitelistedresult = await isWhitelistedmethod.call(User);
      const isWhitelisted = isWhitelistedresult.decoded[0];
      console.log ("isWhitelisted",isWhitelisted);
      setUserisWhitelisted(isWhitelisted);
      const onlyWhitelistedABI = findABI(abi, 'onlyWhitelisted');
      const onlyWhitelistedmethod = connex.thor.account(BVMSC).method(onlyWhitelistedABI);
      const onlyWhitelistedresult = await onlyWhitelistedmethod.call();
      const onlyWhitelisted = onlyWhitelistedresult.decoded[0];
      console.log ("onlyWhitelisted",onlyWhitelisted);
      setclaimWhitelisted(onlyWhitelisted);
  
      if(isWhitelisted == true && onlyWhitelisted == true){
        setisWhitelisted(true);
      }
      if(isWhitelisted == false && onlyWhitelisted == true){
        setisWhitelisted(false);
      }else{
        setisWhitelisted(true);
      }
  
      const priceABIVET = findABI(abi, 'priceVET');
      const method6 = connex.thor.account(BVMSC).method(priceABIVET);
      const result6 = await method6.call();
      const priceVET = result6.decoded[0];
      console.log (priceVET);
      setPriceVET(priceVET);    
      const pausedABI = findABI(abi, 'paused');
      const pausedmethod = connex.thor.account(BVMSC).method(pausedABI);
      const resultpaused = await pausedmethod.call();
      const paused = resultpaused.decoded[0];
     setpaused(paused);
 
   
     // setLoading(false);
      }
    };

    const getReceipt = async (txId) => {
      let receipt = null;
      while (receipt == null) {
          receipt = await connex.thor.transaction(txId).getReceipt();
          await sleep(1000);
      }
      return receipt;
  
}
     
      const mint = async () => {
        const mintAbi = findABI(abi, 'mint');
        const mintMethod = connex.thor.account(BVMSC).method(mintAbi).caller(walletAddress);
        const totalPrice = new BigNumber(PriceVET * mintAmount).toFixed();
        console.log(totalPrice);
        let clauses = [];

          clauses.push(mintMethod.value(totalPrice).asClause(mintAmount));
          console.log("PriceVETtotal", totalPrice);
         
       
        setLoading(true);
          let resp = null;
          
           resp = await connex.vendor.sign('tx', clauses)
            .signer(User).request().then(result => {
              console.log(result);
              
              toast.success('Mint succeeded!', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
             
                  return result;
                  
          }).catch(error => {
            toast.error('Something went wrong try again !', {
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
            });
            setLoading(false);
            console.log(error);
          });
           const receipt = await getReceipt(resp.txid);
          if (receipt.reverted) {
              toast.error('Transaction failed!', {
                  position: "top-right",
              });
          } else {
              toast.success('Transaction succeeded!', {
                  position: "top-right",
              });
          }
           await loadinfo();
           await GetUserTokens();
        
      };

  
    const decrementMintAmount = () => {
      let newMintAmount = mintAmount - 1;
      if (newMintAmount < 1) {
        newMintAmount = 1;
      }
      setMintAmount(newMintAmount);
    };
  
    const incrementMintAmount = () => {
      let newMintAmount = mintAmount + 1;
      if (newMintAmount > 1) {
        newMintAmount = 1;
      }
      
        setMintAmount(newMintAmount);
    };
  

            
        
	return (
		<div className="BVM">  
     
	
      <s.Container flex={1} jc={"center"} ai={"center"} style={{textAlign: "center", border: "4px dashed var(--secondary)", boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",}} >
       <s.Container flex={1} jc={"center"}ai={"center"}style={{backgroundColor: "rgba(0, 0, 0, 0.5)",textAlign: "center", padding: 0,borderRadius: 0,boxShadow: "211px 211px 211px 211px rgba(10,10,10,10)",}}>   
            <s.TextDescription
              style={{
                textAlign: "center",
                fontSize: 20,
                color: "var(--primary-text)",
              }}
            >
     <img src={Logo} style={{width:"60%"}}/>
      </s.TextDescription>
<Row>
  <Col sm={12}>

  <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 36,
                fontWeight: "bold",
                color: "var(--accent-text)",
                margin: "0 auto", // Add this line to center the text
                
              }}
            > 

              INTRODUCTION TO VECHAIN NFTS

            </s.TextTitle>

            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                color: "var(--accent-text)",
                margin: "0 auto", // Add this line to center the text
                
              }}
            > 

              How to Claim your First FREE NFT?

            </s.TextTitle>


            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 14,
                color: "var(--accent-text)",
                margin: "0 auto", // Add this line to center the text
                
                
              }}
            > 
        
             <s.SpacerSmall/>
             Step-by-Step Guide: How to Get Your Free Hamster NFT on VeChain <br/><br/>

             
Step 1: Join the BlackVeMarket Discord<br/><br/>

Create an account on Discord if you don't have one.<br/>
Join the <a href="https://discord.gg/VSn6X9eNEm">BlackVeMarket</a> Discord community.<br/><br/>

Step 2: Download the VeChainThor Mobile Wallet or Sync2/VeWorld<br/><br/>

If you're accessing the platform through a mobile device, download the VeChainThor Mobile Wallet.<br/>
If you're accessing the platform through a PC, use Sync2 or VeWorld.<br/><br/>

Find the wallets <a href="https://www.vechain.org/wallets/">here</a><br/><br/>

Step 3: Verification and Access to the Hamster Room<br/><br/>

Follow the verification process in the Discord server.<br/>
Once verified, you'll gain access to the exclusive hamster room.<br/><br/>
Inside the hamster room you will enjoy 24/7 Support to start your NFT Journey!<br/><br/>

Step 4: Drop Your Public Wallet Address<br/><br/>

Share your public wallet address in the hamster room to confirm your participation.<br/><br/>

Step 5: Whitelist Access and Free Minting<br/><br/>

The BlackVeMarket team will provide you with whitelist access.<br/>
You'll be able to mint a Hamster NFT for free! No gas fees required.<br/><br/>

Step 6:  Enjoy Your Hamster NFT<br/><br/>

Check out your unique hamster NFT on BlackVeMarket.com.<br/>
Explore the marketplace for more breathtaking art.<br/><br/>

Want to earn more hamsters? Stay active in our Discord to unlock Exclusive rewards!<br/><br/>

Step 7: Listing and Buying NFTs<br/><br/>

If you wish to sell or buy more NFTs, you can do so on the marketplace.<br/>
Learn more inside our discord!<br/><br/>

Please Note:<br/><br/>

Each wallet is limited to minting one Hamster NFT.<br/>
The BlackVeMarket team covers all gas fees for the minting process.<br/>
Don't forget to explore the wide range of NFTs available on BlackVeMarket and discover more amazing collections.<br/><br/>
<br/>
             <br/>
             {User ? <> You're connected </> : <>Please connect your wallet</> }
             <br/>
             <br/>
             <br/>
   
            </s.TextTitle>
            {!PAUSED ? ( <>
    {User !== null ? (
                <>
         <s.TextTitle
          style={{  
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
            color: "var(--accent-text)",
          }}
        >
          <div>__________________</div>
         Only {balanceNFTS} Chubby Hamster Club NFTs left!
          <div>You total minted: {UserNFTS}</div>
         
        </s.TextTitle>
     
       
    {Number(balanceNFTS) <= null ? (
  <>
    <s.TextTitle
      style={{ textAlign: "center", color: "var(--accent-text)" }}
    >
    The mint has ended. 
    </s.TextTitle>
    <s.SpacerSmall/>
  
  </>
) : (<>

    <s.Container ai={"center"} jc={"center"}>
    {claimWhitelisted === true? (
                  <>{IsUserWhitelisted === false? (
                  <> You're not whitelisted </>) : (<>
                 <s.SpacerSmall />  
                   You're whitelisted  </> )} 
                    
                    </>):(<>Please note! Only 1 NFT per Wallet/Person!</>
                    )}

            </s.Container>  
    <s.TextDescription
      style={{ textAlign: "center", color: "var(--accent-text)" }}
    >
    
    {balanceNFTS <= 0 ? 'Minting disabled' : ''}
    <div>__________________</div>
 
    </s.TextDescription>  
    <s.Container flex={1} ai={"center"}  style={{ padding: 0, backgroundColor: "var(--primary)"}}>
    <ButtonGroup aria-label="Basic example">
        <Button variant="outline-warning" size ="lg" onClick={() => decrementMintAmount()} >-</Button> 
        <Button variant="outline-warning" size ="lg" onClick={balanceNFTS ? mint: null} disabled={balanceNFTS <= 0 ? 1 : 0 || UserIsWhitelisted == false}> Mint now: {mintAmount}</Button>
         <Button variant="outline-warning" size ="lg" onClick={balanceNFTS ? incrementMintAmount: null} disabled={balanceNFTS <= 0 ? 1 : 0|| claimWhitelisted == true && UserNFTS >= 300 } >+</Button> 
        </ButtonGroup>
        
        <br/>Go to BlackVeMarket or Assets to see your NFT
        </s.Container>
        </>)}
        </> ) : (null)}</>): (<h3 style={{color:'White'}}> !!! THE MINT HAS NOT STARTED YET !!!</h3>)}
                  
      </Col>
     
    </Row>
    <Col sm={6}>
     <s.SpacerSmall/>
     <Carousel fade>
      <Carousel.Item>
      <StyledImg3 alt={"1"} src={ex1} className="d-block w-100"/> 
      </Carousel.Item>
      <Carousel.Item>
      <StyledImg3 alt={"2"} src={ex2} className="d-block w-100"/> 
      </Carousel.Item>
      <Carousel.Item>
      <StyledImg3 alt={"3"} src={ex3} className="d-block w-100"/>    
      </Carousel.Item>
    </Carousel>   

    <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 36,
                fontWeight: "bold",
                color: "var(--accent-text)",
                margin: "0 auto", // Add this line to center the text
                
              }}
            > 
              <br/>
              
              LEARN MORE IN OUR DISCORD SERVER<br/>
              <br/>

            </s.TextTitle>        
     
    </Col>
     </s.Container>  
     </s.Container> 


	</div>

			
	);
}
export default Mint;