
import WorkButton from "./buttons/WorkButton";
import {useEffect, useState} from "react";
import ClaimRewardsButton from "./buttons/ClaimRewardsButton";
import {ProgressBar,Button, Card} from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import CoinJar from '../pages/CoinJar';
import Collapse from 'react-bootstrap/Collapse';
export default function PlayerPanel({ contract, player, workHandler, claimReward }) {
    const [open, setOpen] = useState(false);
    //console.log(player)
    return (
        <div>
              <ToastContainer />
             
            <div className="player-info-panel">
           
           
            {/* <p><strong>BalancePool</strong> : {Math.round(Number(player.getBalanceOfpool))} $BVC.</p> */}

              
{/*               
                <p><strong>Balance</strong> : {player.balance} <br/></p> */}
             <CoinJar/>
                <Card border="info" >
                
      
            <Button variant="outline-info" 
																	onClick={() => setOpen(!open)}
																	aria-controls="example-collapse-text"
																	aria-expanded={open}
																>
																	<strong>Your NFT collection balances 👀</strong>
																</Button>					
																<div style={{ minHeight: '20px' }}>
																<Collapse in={open} dimension="width">
																<div id="example-collapse-text">

                                                                <p> <strong> META LANDS </strong> : {player.balanceMETALANDS} NFTs (5 $BVC per NFT/day)</p>
                                                                <p> <strong> VeThugs </strong> : {player.balanceVeThugs} NFTs (3 $BVC per NFT/day)</p>
                                                                <p> <strong> Vemons </strong> : {player.balanceVemons} NFTs (3 $BVC per NFT/day)</p>
                                                                <p> <strong> VeShawties </strong> : {player.balanceVeShawties} NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> Metaversials </strong> : {player.balanceMetaversials} NFTs (2 $BVC per NFT/day)</p>
                                                                <p> <strong> AlterEgos </strong> : {player.balanceAlterEgos} NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> BabyDragons </strong> : {player.balanceBabyDragons} NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> WildTeen </strong> : {player.balanceWildTeen} NFTs (2 $BVC per NFT/day)</p>
                                                                <p> <strong> TamedTeen </strong> : {player.balanceTamedTeen} NFTs (2 $BVC per NFT/day)</p>
                                                                <p> <strong> Puraties </strong> : {player.balancePuraties} NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> GangsterGorillaz </strong> : {player.balanceGangsterGorillaz} NFTs (2 $BVC per NFT/day)</p>
                                                                <p> <strong> GorillaPetz </strong> : {player.balanceGorillaPetz} NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> Venonymous </strong> : {player.balanceVenonymous} NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> InkaEmpire </strong> : {player.balanceInkaEmpire} NFTs (2 $BVC per NFT/day)</p>
                                                                <p> <strong> InkaConquest </strong> : {player.balanceInkaConquest} NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> GodsOfOlympus </strong> : {player.balanceGodsOfOlympus} NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> Bullies </strong> : {player.balanceBullies} NFTs NFTs (1 $BVC per NFT/day)</p>
                                                                <p> <strong> Goatz </strong> : {player.balanceGoatz} NFTs NFTs (2 $BVC per NFT/day)</p>
                                                                <p> <strong> SmartGoatz </strong> : {player.balanceSmartGoatz} NFTs (2 $BVC per NFT/day)</p>
                                                                <p> <strong> SuperGoatz </strong> : {player.balanceSuperGoatz} NFTs (2 $BVC per NFT/day)</p>
                                                                <p> <strong> VeFrightened </strong> : {player.balanceVeFrightened} NFTs (1 $BVC per NFT/day)</p>
															    <p> <strong> GanjaGirls </strong> : {player.balanceGanjaGirls} NFTs (1 $BVC per NFT/day)</p>
															</div>
															</Collapse>
															</div>
  

    
    </Card> <Card border="info" >
          
                <p><strong>Ratio 1 VET for BVC</strong> : {player.BVCprice} 
               <br/><strong>Your balance is {player.balance} <br/>
                 approx value</strong> : {Math.round(Number(player.VETvalue))} $VET </p>
                <p><strong>Real time </strong> : {player.realdate + '  ' + player.realtime }</p>
                <p><strong>Last claimed</strong> : {player.LastWorkedDate + '  ' + player.LastWorkedTime }</p>
                <p><strong>Claim available </strong> : {player.WorkingDoneDate + '  ' + player.WorkingDoneTime }</p>
                <ProgressBar animated now={player.percentage} label={` ${player.percentage}%`} variant="info" />
<br/>
                <p> <strong>Daily rewards </strong> : {player.BalanceNFTSrewardsDaily} $BVC. Staked {player.days} days</p>
    
             <p> <strong>Total rewards to be claimed </strong> :  {player.TstakingRewards} $BVC.</p>
            <p><strong>Unclaimed rewards</strong> : {player.unclaimedRewards}</p>    
            <h6> The $BVC rewards are accumulating daily!</h6> 
    </Card>
                {/* <p><strong>Worker Level </strong> : {player.level}</p>  */}
                
     
              
           
            </div>
            <div className='buttons-clm'>
                <WorkButton player={player} functionCall={workHandler} />
                <ClaimRewardsButton player={player} functionCall={claimReward} />

            </div>
        </div>
    )
}