import {connex, User, CoinContract, sleep} from "../constants/Blockchain";
import {useEffect, useState} from "react";
import {CoinAbi} from "../constants/SCcoin";
import "../pages/BVM.css";
import * as s from "../styles/globalStyles";
import styled from "styled-components";

import {toast} from "react-toastify";
import {Loader} from "../components/Functions/Loader";
import Collapse from 'react-bootstrap/Collapse';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';


function CoinJar() {
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(0);
    const [sendAmount, setSendAmount] = useState(1);
    const [recipient, setRecipient] = useState('')
    const [open, setOpen] = useState(false);
    const findABI = (SCABI, method) => SCABI[SCABI.findIndex(mthd => mthd.name === method)];

    useEffect(() => {
 
      console.log("refresh",User)
      if (User){
       currentCoins();
      }
    }, []);


  const currentCoins = async () => {
    const balanceOfABI = findABI(CoinAbi, 'balanceOf');
    const balanceOfMethod = CoinContract.method(balanceOfABI);
    const balanceOfresult = await balanceOfMethod.call(User);
    const getBalanceOf = +balanceOfresult.decoded[0];
    setBalance(getBalanceOf);
    setLoading(false);
  }


  const transferTokens = async () => {
    const amount = (sendAmount * ("1e" + 18)).toLocaleString('fullwide', {useGrouping:false});
    const transferAbi = findABI(CoinAbi, 'transfer');
    const transferMethod = CoinContract.method(transferAbi).caller(User);
    setLoading(true);
    let resp = null;
    
     resp =await connex.vendor.sign('tx',  [transferMethod.asClause(recipient, amount)])
    .signer(User).request().then(result => {
    
      toast.success('Sent '+sendAmount+' $BVC succeeded!', {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
  
    return result;
    
      
}).catch(error => {
    toast.error('Something went wrong try again !', {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      console.log(error);
return;
   
});

const receipt = await getReceipt(resp.txid);
if (receipt.reverted) {
    toast.error('Transaction failed!', {
        position: "top-right",
    });
} else {
    toast.success('Transaction succeeded!', {
        position: "top-right",
    });
}
 await currentCoins();
 
}



const getReceipt = async (txId) => {
  let receipt = null;
  while (receipt == null) {
      receipt = await connex.thor.transaction(txId).getReceipt();
      await sleep(1000);
  }

  return receipt;

}

if (loading) {
  return Loader(loading);
}


    return (
     <div className="CoinJar">
 
	


<Card border="dark" >
                       <Card.Body>

                         
           <s.TextTitle style={{textAlign: "center", fontSize: 23, fontWeight: "bold", color: "var(--accent-text)", }}> 
           <h7 style={{color: 'white'}}> BVM STAKING DASHBOARD </h7>    
          </s.TextTitle>
             Balance: {Math.round(Number(balance) / ("1e" + 18))} $BVC
             {User ?
                    <>
       <br/>
   
                               
                                    <Button variant="outline-warning" 
																	onClick={() => setOpen(!open)}
																	aria-controls="example-collapse-text"
																	aria-expanded={open}
																>
																	Transfer
																</Button>					
																<div style={{ minHeight: '30px' }}>
																<Collapse in={open} dimension="width">
																<div id="example-collapse-text">
															
																
                                Amount
                                    <br/>
                                    <input style={{ width: '14rem' }} type="number" placeholder="Amount $BVC" onChange={num => setSendAmount(Number(num.target.value))}  max={Number(balance) / ("1e" + 18)} min={1}/>
                                    <br/> 
                                    <s.SpacerSmall/>
                                     VeChain Wallet Address
                                    <br/>
                                    <input style={{ width: '14rem' }}type="email" placeholder="0x*******" onChange={address => setRecipient(address.target.value)}/>
                                    <br/>
                                    <s.SpacerSmall/>
                                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <Button variant="outline-warning" onClick={() => transferTokens()}> Send: {sendAmount}</Button>
                                   
                                    </s.Container>
																	<br/>
																	Disclaimer: Enter a valid VeChain address. BVM is not responsible for any $BVC losses.
																	
														
															
																
															</div>
															</Collapse>
															</div>
                              
                            
                    </>
                    : null
                }
                
              
         
                </Card.Body>
                </Card>
      
      </div> 
    )};
    
export default CoinJar;
    