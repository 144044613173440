import { useState,useEffect,useRef } from "react";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import {connex, User,BVMSC, sleep,MaxSupply} from "../constants/Blockchain";
import {toast} from "react-toastify";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Collapse from 'react-bootstrap/Collapse';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import {abi} from "../constants/BVMSC";
import {Loader} from "../components/Functions/Loader";
import { Fragment } from 'react';
import ScrollButton from '../components/ScrollButton';
import {selectToken, batchCheck} from "../components/Functions/BatchFunctionality";
export const StyledImg = styled.img`
  display: center;
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 10%;
  width: 76px;
  @media (min-width: 350px) {
    width: 210px;
  }
  @media (min-width: 500px) {
    width: 350px;
  }
  transition: width 0.5s;
`;

import('../pages/BVM.css');

const Assets = () => {
	const [metadata, setMetadata] = useState([]);
	const [recipient, setRecipient] = useState('');
	const [balanceOf, setBalanceOf] = useState([]);
	const isApprovedForAll = useRef(false);
  const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
  const [batchTokenIds, setBatchTokenIds] = useState([]);
  const [sortType, setSortType] = useState('');
  const [batchAction, setBatchAction] = useState('');
	const [attributesModelData, setAttributesModelData] = useState([]);
  const findabi= (SCABI, method) => SCABI[SCABI.findIndex(mthd => mthd.name === method)]
  const [datasort, setData] = useState([]);
  const [key, setKey] = useState('cards');
    useEffect(() => {
        setLoading(true);
    
     console.log("refresh",User)
     if (User){
        GetUserTokens();
     } }, []);

useEffect(() => {
    setLoading(true);
    console.log("hier2")
 const sortArray = type => {
   
      //  console.log("metadtain", metadata)

     const types = {
        tokenid: 'edition',
        rank: 'rank',
        rarity: 'rarity',

      };
   
    console.log('types', types[type]);
    const sortProperty = types[type];
    console.log("sortProperty",sortProperty);
    let sorted = null;
    if(sortProperty === 'claim'){
        sorted = [...metadata].sort((a, b) => b[sortProperty] - a[sortProperty]);
    }else{
        sorted = [...metadata].sort((b, a) => b[sortProperty] - a[sortProperty]);
    }
   
   // console.log("sorted",sorted)
    setData(sorted);
  
  };
  sortArray(sortType);
  setLoading(false);
	  }, [sortType]);


const GetUserTokens = async () => {
    setLoading(true);
		const walletOfOwnerabi = findabi(abi, 'walletOfOwner');
		const walletOfOwnermethod = connex.thor.account(BVMSC).method(walletOfOwnerabi);
		const walletOfOwnerresult = await walletOfOwnermethod.call(User);
		const walletofOwnerNFTs =  walletOfOwnerresult.decoded[0];//['1','100','10000'];
  
		//console.log('balance', walletofOwnerNFTs);
    
        setBalanceOf(walletofOwnerNFTs.length);
let metas = [];

for(let id of walletofOwnerNFTs){
//    setLoading(true);
 
     const metadatalink = "https://bvm-marketplace.fra1.digitaloceanspaces.com/chcmeta/"+id+".json"

     //console.log("metadatalink", metadatalink)
var formdata = new FormData();
formdata.append("tokenId", walletofOwnerNFTs.join(","))

    const response = await fetch(metadatalink)
    const json = await response.json();
    [json].map((item) => {
        let attributes = [];
        Object.entries(item['attributes']).map(([key,value])=>{
            attributes.push({trait_type: key, value: value})
        })
        let result = {
            attributes: [...attributes],
            edition: item['edition'],
            image: item['image'],
            
        }
        
       const imagestr = "https://bvm-marketplace.fra1.digitaloceanspaces.com/chc/"+id+".webp"
       
  
        metas.push({result,img: imagestr ,edition: item['tokenId'], rarity: attributes[attributes.length - 1].value.value,
        rank: attributes[attributes.length - 2].value.value,  name: item['name'],})
    })
    console.log("metas", metas)
    setMetadata([...metas]);
}
    if (datasort.length !== walletofOwnerNFTs.length) {
        setData([...metas]);
      
    }
    console.log("_________________");

    setLoading(false);
		        }
            
        if (loading) {
            return Loader(loading);
        }
  
    const transfer = async(tokenId) =>{
		console.log('tokenid', tokenId)
        const transferFromabi = findabi(abi, 'transferFrom')
        const transferFromMethod = connex.thor.account(BVMSC).method(transferFromabi).caller(User);
		console.log('transferFromMethod', transferFromMethod)
        setLoading(true);
        let resp = null;
    resp = await connex.vendor.sign('tx', [transferFromMethod.asClause(
				User,
				recipient,
				tokenId)
			]).comment('Transaction signing - Transfer NFT')
			.request().then(result => {

            toast.success('Transfer NFT succeeded!', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
              });
            
            return result;
          
            }).catch(error => {
                toast.error('Something went wrong try again !', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                  setLoading(false);
                  console.log(error);
                  return;
            });
            
            const receipt = await getReceipt(resp.txid);
            if (receipt.reverted) {
                toast.error('Transaction failed!', {
                    position: "top-right",
                });
            } else {
                toast.success('Transaction succeeded!', {
                    position: "top-right",
                });
            }
            return await GetUserTokens();
               
    }
  
	const getReceipt = async (txId) => {
        let receipt = null;
        while (receipt == null) {
            receipt = await connex.thor.transaction(txId).getReceipt();
            await sleep(1000);
        }
        return receipt;
    
	}
	const handleAttributesClick = (data) => {
	//	console.log('data',data)

        setAttributesModelData(data);
    }

    
	return (

		<div className="BG">  
   
   <Fragment>
   <s.Container 
            
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
            padding: 20,         
            borderRadius: 1000,
            boxShadow: "111px 211px 111px 211px rgba(10,10,10,10)",
            fontFamily: "Impact, 'Charcoal', sans-serif",
            }}
          >  
	 
      <s.TextDescription
              style={{
                textAlign: "center",
                fontSize: 22,
                color: "var(--primary-text)",
                fontFamily: "Impact, 'Charcoal', sans-serif",
              }}
            >
     Your Chubby Hamsters<br/>
      </s.TextDescription>
                   {User ?
                    <>
                                {isApprovedForAll.current ?
                                
                                   <Button variant="outline-success"  size="sm" onClick={() =>  setApprovalForAll()}>Enable staking</Button>
                                    : <>

                                 
                          <s.SpacerSmall/>
                          {/* <Form.Select onChange={(e) => setSortType(e.target.value)} aria-label="Default select example">
                  <option>Select the sort method</option>
                  <option value="tokenid">Edition</option>
                  <option value="rank">Rank High to Low</option> */}
                  {/* <option value="rarity">Rank Low to High</option> */}
                  {/* </Form.Select> */}
    
                          <s.TextDescription
              style={{
                textAlign: "center",
                fontSize: 20,
                color: "var(--primary-text)",
                fontFamily: "Impact, 'Charcoal', sans-serif",
              }}
            >	{Number(balanceOf) > null ? (
				<>
			You got {balanceOf} Chubby Hamsters!
			<br/>
    
        
        
			</> ) : ( <>You don't own any Hamsters...</>)}
		
             </s.TextDescription>
                                    </>
                                }
                            </>
                            : <>Please connect your wallet</>
                }
          <s.Container>
          
          <div className="gap-2" style={{ display: "flex", flexWrap: "wrap" }}>
    
	{datasort.map((token, index) => (
	 		<div key={index} onClick={(e) => selectToken(e, index, token, setBatchTokenIds, batchTokenIds, batchAction)} id={'card-' + index} >
	 				 <Card 
						//  bg={'Dark'.toLowerCase()}
						 key={'Dark'}
            text={'Dark'.toLowerCase() === 'light' ? 'light' : 'white'}
						 style={{ width: '20rem', height: '100%'}}
						border="Light">
					    <Card.Header>{token.name}</Card.Header>
            
      
              {'Rank : '+ token.rank}<br/>
              {/* {'Rarity : '+ token.rarity}<br/> */}
                        <Card.Img id={"image"}variant="top" src={token.img} /> 
                
                        <ListGroup >
                    
                        <s.SpacerSmall/>
                        <Button variant="outline-light"  size="sm"	onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}	>
                         Transfer
                       </Button>	
               
                       <div style={{ minHeight: '0px' }}>
                       <Collapse in={open} dimension="width">
                       <div id="example-collapse-text">
                         <input style={{ width: '100%', zindex: '999', position:'relative'}}type="email" placeholder="VeChain Wallet Address" onChange={address => setRecipient(address.target.value)}/>				
                         <Button variant="outline-danger"  size="sm" onClick={() => transfer(token.result.edition)}className="button"> Transfer: {token.result.edition}	</Button>
                         <br/>
                         Disclaimer: Enter a valid VeChain address. BVM is not responsible for any NFT losses.
                     </div>
                     </Collapse>

               </div>
               <s.SpacerSmall/>               {/* </ListGroup.Item>	  */}
													</ListGroup>
												
													</Card>
                                                  
                                                    
                                                    </div>					       
				))}
</div>

  </s.Container>
  

    <ScrollButton />
    </s.Container>
</Fragment>	
	</div>

			
	);
}
export default Assets;




        

                       