import { useEffect, useState, useCallback,Component } from 'react';
import { ChainId, Token, WVET, Fetcher, Trade, Route, TokenAmount, TradeType } from 'vexchange-sdk'

import Connex from '@vechain/connex'
import { BigNumber } from '@ethersproject/bignumber';
import {toast} from "react-toastify";
import {ProgressBar,Button} from "react-bootstrap";

import { BVMSC, STAKINGSC, sleep, CoinContract, contract, User, WalletAddress, NodeS, connex, VeThugs,
    Vemons,
    VeShawties,
    Metaversials,
    AlterEgos,
    BabyDragons,
    WildTeen,
    TamedTeen,
    Puraties,
    GangsterGorillaz,
    GorillaPetz,
    Venonymous,
    InkaEmpire,
    InkaConquest,
    GodsOfOlympus,
    Bullies,
    Goatz,
    SmartGoatz,
    SuperGoatz,
    VeFrightened,
    GanjaGirls} from '../constants/Blockchain.jsx';
import Layout from '../components/Layout';
import PlayerPanel from '../components/PlayerPanel';
import ButtonConnect from '../components/buttons/ButtonConnect';
import Loading from '../components/Loading';
import Coinjar from './CoinJar';

import * as s from "../styles/globalStyles";
import styled from "styled-components";

import {abi2} from "../constants/abi2.jsx";
import {abiCoin} from "../constants/abiCoin.jsx";

function Staking() {
    const [currentAccount, setCurrentAccount] = useState(null);
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [player, setPlayer] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const findABI = (SCABI, method) => SCABI[SCABI.findIndex(mthd => mthd.name === method)]
  
    const checkWalletIsConnected = async () => {


        if (!User) {
            console.log("Needs SYNC intalled.")
            return;

        } else {
            console.log("Wallet detected.",User)
        }

        const accounts = User

        if (accounts.length !== 0) {
            const account = accounts[0];
            console.log("Found an authorized account.");
            setIsWalletConnected(true);
            setCurrentAccount(account);
        } else {
            console.log("No authorized account found");
        }
    }

    const connectWalletHandler = async () => {

        if (!connex) {
            alert("Please install SYNC2.");
        }

        try {
            connex
            .vendor
            .sign('cert', {
                purpose: 'identification',
                payload: {
                    type: 'text',
                    content: 'Connect your wallet. ' 
                }
            })
            .request()
            .then(result => {
                localStorage.setItem(WalletAddress, result.annex.signer);
                document.location.href = "/";
            })
            console.log("Found an account: ", WalletAddress);
            setCurrentAccount(WalletAddress);
        } catch (err) {
            console.log(err)
        }
    }

    const claimReward = async () => {
      
            const Abi = findABI(abi2, 'claimReward')
            const workMethod = connex.thor.account(STAKINGSC).method(Abi).caller(User);
            console.log(workMethod);
            let clauses = [];

            clauses.push(workMethod.asClause());
           
           
         
            setIsLoading(true);
            let resp = null;
            
             resp = await connex.vendor.sign('tx', clauses)
              .signer(User).request().then(result => {
                console.log(result);
            // setLoading(true);
               
           
                toast.success('Claim rewards to your wallet succeeded!', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
              
                return result;
                
            }).catch(error => {
                toast.error('Something went wrong try again !', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                //   setLoading(false);
                console.log(error);
                return;
            });
            const receipt = await getReceipt(resp.txid);
            if (receipt.reverted) {
                toast.error('Transaction failed!', {
                    position: "top-right",
                });
            } else {
                toast.success('Transaction succeeded!', {
                    position: "top-right",
                });
            }
          
        
            setIsLoading(false);

            setToggle(prevState => !prevState);
         
        
    }

    const workHandler = async () => {
     
            const Abi = findABI(abi2, 'work')
            const workMethod = connex.thor.account(STAKINGSC).method(Abi).caller(User);
            console.log(workMethod);
            let clauses = [];

            clauses.push(workMethod.asClause());
           
             setIsLoading(true);
            let resp = null;
            
             resp = await connex.vendor.sign('tx', clauses)
              .signer(User).request().then(result => {
                console.log(result);
            // setLoading(true);
               
           
                toast.success('START/CLAIM Staking succeeded!', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
              
                return result;
                
            }).catch(error => {
                toast.error('Something went wrong try again !', {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined,
                  });
                  setIsLoading(false);
                console.log(error);
                return;
            });
            const receipt 
            = await getReceipt(resp.txid);
            if (receipt.reverted) {
                toast.error('Transaction failed!', {
                    position: "top-right",
                });
            } else {
                toast.success('Transaction succeeded!', {
                    position: "top-right",
                });
            }
            setIsLoading(false);
            setToggle(prevState => !prevState);
        
     
    }



    const getReceipt = async (txId) => {
        let receipt = null;
        while (receipt == null) {
            receipt = await connex.thor.transaction(txId).getReceipt();
            await sleep(1000);
        }
        return receipt;
    
  }
       



  
  
  

    const updatePlayerValues = async () => {


            const balanceBVCOfABI = findABI(abiCoin, 'balanceOf');
            const balanceOfMethodpool = CoinContract.method(balanceBVCOfABI);
            const balanceOfresultpool = await balanceOfMethodpool.call("0x89F3D6816Ea30f24ba02FD9ad63B9701DbF46529");
            let getBalanceOfpool = +balanceOfresultpool.decoded[0]/ ("1e" + 18);
            //  console.log(getBalanceOfpool)
            const balanceOfABI = findABI(abiCoin, 'balanceOf');
            const balanceOfMethod = CoinContract.method(balanceOfABI);
            const balanceOfresult = await balanceOfMethod.call(User);
            let balance = +balanceOfresult.decoded[0]/ ("1e" + 18);

            const balanceOfNFTABI = findABI(abiCoin, 'balanceOf');
            const balanceOfMETALANDSMethod = connex.thor.account(BVMSC).method(balanceOfNFTABI);
            const balanceOfMETALANDSresult = await balanceOfMETALANDSMethod.call(User);
            let balanceMETALANDS = +balanceOfMETALANDSresult.decoded[0];

            const balanceOfVeThugsMethod = connex.thor.account(VeThugs).method(balanceOfNFTABI);
            const balanceOfVeThugsResult = await balanceOfVeThugsMethod.call(User);
            let balanceVeThugs = +balanceOfVeThugsResult.decoded[0];
            
            const balanceOfVemonsMethod = connex.thor.account(Vemons).method(balanceOfNFTABI);
            const balanceOfVemonsResult = await balanceOfVemonsMethod.call(User);
            let balanceVemons = +balanceOfVemonsResult.decoded[0];
            
            const balanceOfVeShawtiesMethod = connex.thor.account(VeShawties).method(balanceOfNFTABI);
            const balanceOfVeShawtiesResult = await balanceOfVeShawtiesMethod.call(User);
            let balanceVeShawties = +balanceOfVeShawtiesResult.decoded[0];
            
            const balanceOfMetaversialsMethod = connex.thor.account(Metaversials).method(balanceOfNFTABI);
            const balanceOfMetaversialsResult = await balanceOfMetaversialsMethod.call(User);
            let balanceMetaversials = +balanceOfMetaversialsResult.decoded[0];
            
            const balanceOfAlterEgosMethod = connex.thor.account(AlterEgos).method(balanceOfNFTABI);
            const balanceOfAlterEgosResult = await balanceOfAlterEgosMethod.call(User);
            let balanceAlterEgos = +balanceOfAlterEgosResult.decoded[0];
            
            const balanceOfBabyDragonsMethod = connex.thor.account(BabyDragons).method(balanceOfNFTABI);
            const balanceOfBabyDragonsResult = await balanceOfBabyDragonsMethod.call(User);
            let balanceBabyDragons = +balanceOfBabyDragonsResult.decoded[0];
            
            const balanceOfWildTeenMethod = connex.thor.account(WildTeen).method(balanceOfNFTABI);
            const balanceOfWildTeenResult = await balanceOfWildTeenMethod.call(User);
            let balanceWildTeen = +balanceOfWildTeenResult.decoded[0];
            
            const balanceOfTamedTeenMethod = connex.thor.account(TamedTeen).method(balanceOfNFTABI);
            const balanceOfTamedTeenResult = await balanceOfTamedTeenMethod.call(User);
            let balanceTamedTeen = +balanceOfTamedTeenResult.decoded[0];
            
            const balanceOfPuratiesMethod = connex.thor.account(Puraties).method(balanceOfNFTABI);
            const balanceOfPuratiesResult = await balanceOfPuratiesMethod.call(User);
            let balancePuraties = +balanceOfPuratiesResult.decoded[0];
            
            const balanceOfGangsterGorillazMethod = connex.thor.account(GangsterGorillaz).method(balanceOfNFTABI);
            const balanceOfGangsterGorillazResult = await balanceOfGangsterGorillazMethod.call(User);
            let balanceGangsterGorillaz = +balanceOfGangsterGorillazResult.decoded[0];
            
            const balanceOfGorillaPetzMethod = connex.thor.account(GorillaPetz).method(balanceOfNFTABI);
            const balanceOfGorillaPetzResult = await balanceOfGorillaPetzMethod.call(User);
            let balanceGorillaPetz = +balanceOfGorillaPetzResult.decoded[0];

            // Venonymous
            const balanceOfVenonymousMethod = connex.thor.account(Venonymous).method(balanceOfNFTABI);
            const balanceOfVenonymousResult = await balanceOfVenonymousMethod.call(User);
            let balanceVenonymous = +balanceOfVenonymousResult.decoded[0];

            // Inka Empire
            const balanceOfInkaEmpireMethod = connex.thor.account(InkaEmpire).method(balanceOfNFTABI);
            const balanceOfInkaEmpireResult = await balanceOfInkaEmpireMethod.call(User);
            let balanceInkaEmpire = +balanceOfInkaEmpireResult.decoded[0];

            // Inka Conquest
            const balanceOfInkaConquestMethod = connex.thor.account(InkaConquest).method(balanceOfNFTABI);
            const balanceOfInkaConquestResult = await balanceOfInkaConquestMethod.call(User);
            let balanceInkaConquest = +balanceOfInkaConquestResult.decoded[0];

            // Gods of Olympus
            const balanceOfGodsOfOlympusMethod = connex.thor.account(GodsOfOlympus).method(balanceOfNFTABI);
            const balanceOfGodsOfOlympusResult = await balanceOfGodsOfOlympusMethod.call(User);
            let balanceGodsOfOlympus = +balanceOfGodsOfOlympusResult.decoded[0];

            // Bullies
            const balanceOfBulliesMethod = connex.thor.account(Bullies).method(balanceOfNFTABI);
            const balanceOfBulliesResult = await balanceOfBulliesMethod.call(User);
            let balanceBullies = +balanceOfBulliesResult.decoded[0];

            // Goatz
            const balanceOfGoatzMethod = connex.thor.account(Goatz).method(balanceOfNFTABI);
            const balanceOfGoatzResult = await balanceOfGoatzMethod.call(User);
            let balanceGoatz = +balanceOfGoatzResult.decoded[0];

            // Smart Goatz
            const balanceOfSmartGoatzMethod = connex.thor.account(SmartGoatz).method(balanceOfNFTABI);
            const balanceOfSmartGoatzResult = await balanceOfSmartGoatzMethod.call(User);
            let balanceSmartGoatz = +balanceOfSmartGoatzResult.decoded[0];
            // Super Goatz
            const balanceOfSuperGoatzMethod = connex.thor.account(SuperGoatz).method(balanceOfNFTABI);
            const balanceOfSuperGoatzResult = await balanceOfSuperGoatzMethod.call(User);
            let balanceSuperGoatz = +balanceOfSuperGoatzResult.decoded[0];
            // VeFrightened
            const balanceOfVeFrightenedMethod = connex.thor.account(VeFrightened).method(balanceOfNFTABI);
            const balanceOfVeFrightenedResult = await balanceOfVeFrightenedMethod.call(User);
            let balanceVeFrightened = +balanceOfVeFrightenedResult.decoded[0];

            //GanjaGirls
            const balanceOfGanjaGirlsMethod = connex.thor.account(GanjaGirls).method(balanceOfNFTABI);
            const balanceOfGanjaResult = await balanceOfGanjaGirlsMethod.call(User);
            let balanceGanjaGirls = +balanceOfGanjaResult.decoded[0];


            const BalanceNFTSrewardABI = findABI(abi2, 'BalanceNFTSreward');
            const BalanceNFTSrewardsMethod = contract.method(BalanceNFTSrewardABI);
            const BalanceNFTSrewardresult = await BalanceNFTSrewardsMethod.call(User);
            let BalanceNFTSrewardsDaily = parseInt(BalanceNFTSrewardresult.decoded[0]);
            
            const accumulatedRewardsABI = findABI(abi2, 'accumulatedRewards');
            const accumulatedRewardsMethod = contract.method(accumulatedRewardsABI);
            const accumulatedRewardsresult = await accumulatedRewardsMethod.call(User);
            let rewardsTxn = parseInt(accumulatedRewardsresult.decoded[0]);
           // console.log(rewardsTxn)

            const getUnixDayABI = findABI(abi2, 'getUnixDay');
            const getUnixDayMethod = contract.method(getUnixDayABI);
            const getUnixDayresult = await getUnixDayMethod.call();
            let getUnixDay = parseInt(getUnixDayresult.decoded['0']);
            //console.log(getUnixDay)
       
            const playersABI = findABI(abi2, 'players');
            const playersMethod = contract.method(playersABI);
            let getPlayerTxn = await playersMethod.call(User);
         //console.log(getPlayerTxn)

            const canWorkABI = findABI(abi2, 'canWork');
            const canWorkMethod = contract.method(canWorkABI);
            const canWokresult = await canWorkMethod.call(User);
            let canWork = canWokresult.decoded['0'];
           // console.log(canWork)
           const durationABI = findABI(abi2, 'duration');
           const durationMethod = contract.method(durationABI);
           const durationResult = await durationMethod.call();
           let duration =  parseInt(durationResult.decoded['0']);
          
       // console.log('duration',duration)
            let balanceDisplay;
            if (!balance) balanceDisplay = "out of stock $BVC."
            else balanceDisplay = Math.round(Number(balance)) + " $BVC." 

            let rewardDisplay;
            if (!rewardsTxn) rewardDisplay = "No rewards accumulated."
            else rewardDisplay = rewardsTxn + " $BVC."
 
            var worktime = getUnixDay //+ parseInt(getPlayerTxn.decoded['lastWorked']);
            const RealTime = new Date((parseInt(worktime))*1000 );
            console.log('RealTime',RealTime)
            const LastWorked = new Date((parseInt(getPlayerTxn.decoded['lastWorked']))*1000);
            const LastWorkedDate = LastWorked.toLocaleDateString();
            const LastWorkedTime = LastWorked.toLocaleTimeString();

            const WorkingDone = new Date((parseInt(getPlayerTxn.decoded['lastWorked'])+duration)*1000);
            const WorkingDoneDate = WorkingDone.toLocaleDateString();
            const WorkingDoneTime = WorkingDone.toLocaleTimeString();
            const realdate = RealTime.toLocaleDateString();
            const realtime = RealTime.toLocaleTimeString();
            let days = Math.floor((RealTime - LastWorked) / (1000 * 60 * 60 * 24));
            if (days > 10000) {
                days = 0;
            }
            console.log('days',days)
            let TstakingRewards = (BalanceNFTSrewardsDaily * days);

            let percentage = Math.floor((Math.abs(new Date() - LastWorked) / Math.abs(WorkingDone - LastWorked)) * 100);
    
            if (percentage > 100) {
                percentage = 100;
            }
            const BVC = new Token(ChainId.MAINNET, '0x7ae288b7224ad8740b2d4fc2b2c8a2392caea3c6', 18)
         
            // note that you may want/need to handle this async code differently,
            // for example if top-level await is not an option
            const pair = await Fetcher.fetchPairData(BVC, WVET[BVC.chainId],connex)
            const route = new Route([pair], WVET[BVC.chainId])
            const trade = new Trade(route, new TokenAmount(WVET[BVC.chainId], '1000000000000000000'), TradeType.EXACT_INPUT)
            
          //  console.log(trade.executionPrice.toSignificant(6))
           // console.log(trade.nextMidPrice.toSignificant(6))
    
            const BVCprice = trade.nextMidPrice.toSignificant(6)
            const VETvalue= balance/BVCprice;

            setPlayer({
                level: getPlayerTxn.decoded['level'],
                economicLevel: getPlayerTxn.decoded['economicLevel'],
                lastWorked: getPlayerTxn.decoded['lastWorked'],
                balance: balanceDisplay,
                unclaimedRewards: rewardDisplay,
                canWork,
                LastWorked,
                realtime,
                realdate,
                LastWorkedDate,
                LastWorkedTime,
                WorkingDoneDate,
                WorkingDoneTime,
                duration,
                BalanceNFTSrewardsDaily,
                balanceMETALANDS,
                percentage,
                getBalanceOfpool,
                BVCprice,
                VETvalue,
                days,
                TstakingRewards,
                balanceVeThugs,
                balanceVemons,
                balanceVeShawties,
                balanceMetaversials,
                balanceAlterEgos,
                balanceBabyDragons,
                balanceWildTeen,
                balanceTamedTeen,
                balancePuraties,
                balanceGangsterGorillaz,
                balanceGorillaPetz,
                balanceVenonymous,
                balanceInkaEmpire,
                balanceInkaConquest,
                balanceGodsOfOlympus,
                balanceBullies,
                balanceGoatz,
                balanceSmartGoatz,
                balanceSuperGoatz,
                balanceVeFrightened,
                balanceGanjaGirls,
            });

        // } catch (err) {
        //     console.log(err)
        // }
  
    }


    useEffect(() => {
        checkWalletIsConnected();
    }, []);


    useEffect(() => {
        setIsLoading(true);
        updatePlayerValues();
        setIsLoading(false);
    }, [isWalletConnected]);
   
    useEffect(() => {
        setIsLoading(true);
        updatePlayerValues();
        setIsLoading(false);
    }, [toggle]);


    useEffect(() => {
        let interval = setInterval(() => {
            updatePlayerValues();
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    
 

    
 
  
    

    return (
        <div className='main-app'>
    
                {
                    isLoading ?
                        <Loading /> :
                        <div>
                            {currentAccount ?
                            <>
                             {/* <Button variant="outline-light" onClick={async () => setToggle(prevState => !prevState)}>{`Refresh`}</Button> */}
                                <PlayerPanel
                                    contract={contract}
                                    player={player}
                                    workHandler={workHandler}
                                    claimReward={claimReward}
                                    isLoading={isLoading}

                                    /> 
                                   </>
                                    :
                                <ButtonConnect callable={connectWalletHandler} />}
                                
                                
                        </div>

                }

        </div >

    )
}

export default Staking;
