import Connex from '@vechain/connex'
//Testnet tbd
//const NodeS = 'https://testnet.veblocks.net/'
//const Network = 'test'

//const ContractAddress = '0x536B359faBc1D340E658828d71d8AeCC4a519A1B';
//const CoinContract = '0x675b0DDe8A878Ad7c9feEF44E788d681FC733caa';

// mainnet
const Network= 'main';
const NodeS = 'https://mainnet.veblocks.net/';

/////////////////////////////////////////////////////////////////
//const connex = new Connex({node: NodeS, network: Network});
const connex = new Connex({node: "https://vethor-node.vechain.com", network: 'main', noExtension: true});
const WalletAddress = 'wallet';
const User = window.localStorage.getItem(WalletAddress);
const MaxSupply = 11111;
const sleep = m => new Promise(r => setTimeout(r, m))

const _CoinContract = '0x7ae288b7224ad8740b2d4fc2b2c8a2392caea3c6'; // good BVM
const CoinContract = connex.thor.account(_CoinContract);
const STAKINGSC = '0x4E6DaD96d2A4C2890C6442eFfC1c92868b63d95F'; //'0x4E6DaD96d2A4C2890C6442eFfC1c92868b63d95F';
const contract = connex.thor.account(STAKINGSC);
const BVMSC = '0x9d0077323Ef5CFe31482263c2B2f4e2F92282cAe'; //0x5F52412eA07736544D8fc1d873A45f461b404e59
const VeThugs = '0x97F7C8d476183b69f18f810a18baf3f79994a267';
const Vemons = '0x3dBBa9AD9E33bd188EEe8aa2d5c0e7B9894c6209';
const VeShawties = '0xD56340abB721b7c89C6CA3835EFc490dFd66F9Ae';
const Metaversials = '0x313d1fFf2664A2df5A12E99c8C57E50eFA715d73';
const AlterEgos = '0x055Faf8495067864bCb8e8e3eDAdc506D98aF5b3';
const BabyDragons = '0xC22D8cA65Bb9EE4A8b64406f3B0405CC1EbeEc4e';
const WildTeen = '0xBb74D3D8305f6a6B49448746DE7F1c9EffaF0f82';
const TamedTeen = '0x90dc145867F10EC90D4f4432431896Ca8f8Be0e3';
const Puraties = '0x3A07dEc3477C7E1df69c671Df95471EEfCF86175';
const GangsterGorillaz = '0xA5e2EE50Cb49EA4d0a3A520C15aa4cFfAF5eA026';
const GorillaPetz = '0x6cb68e47080Db4E3574f8A50dF6717eEB32E0269';
const Venonymous = '0x2FD3d1E1a3F1E072c89d67301a86a5ba850Ccd4E';
const InkaEmpire = '0x910607db19dCE5651Da4e68950705D6e49bc01a5';
const InkaConquest = '0x9d3837c3188f58ed579F98cfE922dcCEf25D6e95';
const GodsOfOlympus = '0x0b6F1e2220E7498111Db0e56D972F93Dd035Da32';
const Bullies = '0xC9C8964cf25D2C738190F74b8508CdfAC8650b9d';
const Goatz = '0x4D4664aED6f645fB3DeFbbD668B2A4842c029187';
const SmartGoatz = '0x0422D505c9060673f82335C511D8AA9DdB1f7173';
const SuperGoatz = '0x0d0F3E7Ce89405F75B99F0bD6b498c00b6b937CE';

const VeFrightened = '0x4628c4915B8753e8514b2e800272C2BD1c2A93E7';
const GanjaGirls = '0x998C9d999Bd6AF31089E4d3Bc63A7D196f9A27cA';
export {
    connex,
    NodeS,
    Network,
    User,
    WalletAddress,
    MaxSupply,
    BVMSC,
    STAKINGSC,
    contract,
    CoinContract,
    VeThugs,
    Vemons,
    VeShawties,
    Metaversials,
    AlterEgos,
    BabyDragons,
    WildTeen,
    TamedTeen,
    Puraties,
    GangsterGorillaz,
    GorillaPetz,
    Venonymous,
    InkaEmpire,
    InkaConquest,
    GodsOfOlympus,
    Bullies,
    Goatz,
    SmartGoatz,
    SuperGoatz,
    VeFrightened,
    GanjaGirls,
    sleep,
}
 
